import Img1 from '../img/articles/1.jpg';
import Img2 from '../img/articles/2.jpg';
import Img3 from '../img/articles/3.png';
import Img4 from '../img/articles/4.jpg';
const articles = [
  {
    id: 1,
    title: 'Беременность',
    description:
      'Поздравляем, вы скоро станете мамой! Беременность - волшебное состояние, которое влияет на физическое и эмоциональное состояние мамы. Читайте наши статьи, где мы подскажем, как позаботиться о своем здоровье и в полной мере насладиться ожиданием малыша!',
    img: Img1,
    class_name: '',
  },
  {
    id: 2,
    title: 'Новорожденный',
    description:
      'Кроха появился на свет, а вместе с ним - множество вопросов об уходе за новорожденным. Как наладить грудное вскармливание, как правильно купать и пеленать малыша? Об этом и многих других тонкостях ухода за грудничком читайте в наших статьях.',
    img: Img2,
    class_name: 'articles__slide-link_pink',
  },
  {
    id: 3,
    title: 'До года',
    description:
      'Малыш растет, становясь все активнее и стремясь осваивать новые умения. Мамы озадачены вопросами первого прикорма, первых шагов и конечно, первого слова “Мама”, сказанного любимым голоском. В наших статьях много полезной информации о развитии в первый год жизни малыша!',
    img: Img3,
    class_name: 'articles__slide-link_green',
  },
  {
    id: 4,
    title: 'От года до трех лет',
    description:
      'Первые три года жизни крохи особенно важны для его развития и воспитания. Он столькому учится за этот период! Ходить, говорить, есть взрослую еду и использовать горшок! Столько вопросов возникает у мам маленьких непосед. Наши статьи дадут на них ответы и разложат по полочкам всю необходимую информацию.',
    img: Img4,
    class_name: 'articles__slide-link_orange',
  },
];
const ArticleSection = () => {
  return (
    <section className="home-page__articles">
      <div className="articles container">
        <div className="articles__heading">
          <h2 className="articles__header h2">Статьи</h2>
        </div>
        <div className="articles__slider slider slider_light-dots">
          {articles.map((article) => (
            <div className="articles__slide" key={article.id}>
              <div className="articles__slide-img-wrap">
                <img className="articles__slide-img" src={article.img} alt="" />
              </div>
              <span className={`articles__slide-link ${article.class_name}`}>
                {article.title}
              </span>
              <div className="articles__slide-text">
                <p>{article.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="home-page__subscribe subscribe container">
        <div className="subscribe__inner ">
          <img
            className="subscribe__letter"
            src="https://www.sofgigienik.com/template/sofgigienik/img/b-subscribe/letter.svg"
            alt=""
          />
          <div className="subscribe__heading">
            <img
              className="subscribe__icon"
              src="https://www.sofgigienik.com/template/sofgigienik/img/b-subscribe/newspaper.svg"
              alt=""
            />
            <div className="subscribe__header">
              <div className="subscribe__title">
                Узнавайте о новых статьях первыми
              </div>
              <div className="subscribe__subtitle">
                Никаких долгих поисков достоверной информации на просторах
                интернета. Только самые актуальные и полезные статьи для будущих
                и настоящих мамочек от LALAKU! Подпишитесь, чтобы не пропустить!
              </div>
            </div>
          </div>
          <form
            className="subscribe__form"
            id="subscribeForm"
            method="POST"
            action="#"
          >
            <div className="status"></div>
            <input
              className="subscribe__form-email input input-text"
              id="email"
              name="email"
              type="email"
              placeholder="E-mail"
            />
            <input
              type="submit"
              style={{ display: 'none' }}
              value="submit"
              name="submit"
            />
            <button
              type="button"
              id="subscribeButton"
              className="subscribe__form-submit btn btn_color_bright-1 sb_news"
            >
              Подписаться
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ArticleSection;
