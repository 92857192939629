import Header from '../components/Header';
import BreadCrump from '../components/BreadCrump';
import Footer from '../components/Footer';
// import Img from '../img/logo_firmi.png';
import { useStore } from '../context/store';

const About = () => {
  const { openMenu } = useStore();
  return (
    <div className="back-box">
      <Header />
      <main hidden={openMenu}>
        <BreadCrump breadcrumb={[{ name: 'О бренде' }]} />
        <div className="about-page">
          <section className="about-page__header container">
            <h1 className="h1">О нас</h1>
          </section>
          <section className="container">
            <article className="about-article about-article_1 about-article_bg_light">
              <div className="about-article__content">
                <h3 className="about-article__header h3">
                  Компания «Impex Central Asia».
                </h3>
                <div className="about-article__text w-100">
                  <p>Эксклюзивный дистрибьютор на территории Казахстана</p>
                </div>
                {/* <div className="col-12 col-lg-6 my-4">
                  <img
                    src={Img}
                    className="img-fluid about__img img-thumbnail"
                    alt=""
                  />
                </div> */}
              </div>
            </article>
          </section>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default About;
