import { useEffect, useState } from 'react';
import ProductBox from './ProductBox';
import { catalogList } from '../api/api';
import { eldersDiapers, mama, masks, pads, women } from '../api/catalog';
type Props = {
  catalog: string | undefined;
};
type PropArray = {
  id: number;
  brand: string;
  title: string;
  description: string;
  gallery: any[];
  box?: { size?: string; weight?: string }[];
  first_text?: string;
  second_text?: string;
  sizes: string[];
};
const CatalogProductSection: React.FC<Props> = ({ catalog }) => {
  const [data, setData] = useState<PropArray[] | []>([]);

  useEffect(() => {
    switch (catalog) {
      case 'diapers':
        setData(catalogList);
        break;
      case 'elders-diapers':
        setData(eldersDiapers);
        break;
      case '':
        setData(catalogList);
        break;
      case 'masks':
        setData(masks);
        break;
      case 'women':
        setData(women);
        break;
      case 'pads':
        setData(pads);
        break;
      case 'mama':
        setData(mama);
        break;
      default:
        setData([]);
        break;
    }
  }, [catalog]);

  return (
    <section className="catalog-page__products catalog-products container">
      {data?.map((cat) => {
        return <ProductBox cat={cat} key={cat.id} />;
      })}
    </section>
  );
};

export default CatalogProductSection;
