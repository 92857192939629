const AboutSection = () => {
  return (
    <section className="home-about container">
      <div className="home-about__text-content">
        <h2 className="home-about__title h2">О нас</h2>
        <div className="home-about__article">
          Компания «Impex Central Asia» <br />
          Эксклюзивный дистрибьютор на территории Казахстана
        </div>
        <a href="about">
          <button className="home-about__btn btn btn_color_still-2">
            Подробнее
          </button>
        </a>
      </div>
      {/* <div className="home-about__img"></div> */}
    </section>
  );
};

export default AboutSection;
