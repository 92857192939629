import React, { useContext, useState, createContext } from 'react';

type Props = {
  children: React.ReactNode;
};

type StoreContextProps = {
  openMenu: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const StoreContext = createContext<StoreContextProps>({} as StoreContextProps);

export const StoreProvider = ({ children }: Props) => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <StoreContext.Provider value={{ openMenu, setOpenMenu }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
