import { useWindowSize } from '../hooks/useWindowSize';
import Desk1 from '../img/banners/slider-desk-1.jpg';
import Desk2 from '../img/banners/slider-desk-2.jpg';
// import Desk3 from '../img/banners/slider-desk-3.jpg';
import Tab1 from '../img/banners/slider-tab-1.jpg';
import Tab2 from '../img/banners/slider-tab-2.jpg';
// import Tab3 from '../img/banners/slider-tab-3.jpg';
import Mob1 from '../img/banners/slider-mob-1.jpg';
import Mob2 from '../img/banners/slider-mob-2.jpg';
// import Mob3 from '../img/banners/slider-mob-3.jpg';
import AdvancedSlider from './AdvSlider';
import { useState, useEffect } from 'react';

const deskBanners = [Desk1, Desk2];
const tabBanners = [Tab1, Tab2];
const mobBanners = [Mob1, Mob2];

const Banner = () => {
  const [banners, setBanners] = useState(deskBanners);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width)
      setBanners(
        width > 1200 ? deskBanners : width > 768 ? tabBanners : mobBanners,
      );
  }, [width]);

  return (
    <section className="home-page__banner home-banner container">
      <AdvancedSlider slides={banners} />
    </section>
  );
};

export default Banner;
