import Header from '../components/Header';
import SectionCatalog from '../components/SectionCatalog';
import SectionQuest from '../components/SectionQuest';
import Banner from '../components/Banner';
import FAQSection from '../components/FAQSection';
import VideosSection from '../components/VideosSection';
import ArticleSection from '../components/ArticleSection';
import AboutSection from '../components/AboutSection';
import Footer from '../components/Footer';
import { useStore } from '../context/store';

const Home = () => {
  const { openMenu } = useStore();

  return (
    <div className="back-box">
      <Header />
      <main hidden={openMenu}>
        <Banner />
        <SectionQuest />
        <SectionCatalog />
        <FAQSection />
        <VideosSection />
        <ArticleSection />
        <AboutSection />
        <Footer />
      </main>
    </div>
  );
};

export default Home;
