import Img1 from '../img/catalog/1/product1.png';
import Img2 from '../img/catalog/1/product2.png';
import Img3 from '../img/catalog/1/product3.png';
import Img4 from '../img/catalog/1/product4.png';
import Img2_1 from '../img/catalog/2/1.png';
import Img2_2 from '../img/catalog/2/2.png';
import Img2_3 from '../img/catalog/2/3.png';
import Img2_4 from '../img/catalog/2/4.png';
import Img3_1 from '../img/catalog/3/1.png';
import Img3_2 from '../img/catalog/3/2.png';
import Img3_3 from '../img/catalog/3/3.png';
import Img3_4 from '../img/catalog/3/4.png';
import Img4_1 from '../img/catalog/4/1.png';
import Img4_2 from '../img/catalog/4/2.png';
import Img4_3 from '../img/catalog/4/3.png';
import Img4_4 from '../img/catalog/4/4.png';
import Img4_5 from '../img/catalog/4/5.png';
// import Img5_1 from '../img/catalog/5/1.png';
// import Img5_2 from '../img/catalog/5/2.png';
// import Img5_3 from '../img/catalog/5/3.png';
// import Img5_4 from '../img/catalog/5/4.png';
// import Img6_1 from '../img/catalog/6/1.png';
// import Img6_2 from '../img/catalog/6/2.png';
// import Img6_3 from '../img/catalog/6/3.png';
// import Img6_4 from '../img/catalog/6/4.png';
// import Img6_5 from '../img/catalog/6/5.png';
// import Img7_1 from '../img/catalog/7/1.png';
// import Img7_2 from '../img/catalog/7/2.png';
// import Img7_3 from '../img/catalog/7/3.png';
// import Img7_4 from '../img/catalog/7/4.png';
// import Img8_1 from '../img/catalog/8/1.png';
// import Img8_2 from '../img/catalog/8/2.png';
// import Img8_3 from '../img/catalog/8/3.png';
// import Img8_4 from '../img/catalog/8/4.png';
// import Img9_1 from '../img/catalog/9/1.png';
// import Img9_2 from '../img/catalog/9/2.png';
// import Img9_3 from '../img/catalog/9/3.png';
// import Img9_4 from '../img/catalog/9/4.png';
// import Img9_5 from '../img/catalog/9/5.png';
// import Img10_1 from '../img/catalog/10/1.png';
// import Img10_2 from '../img/catalog/10/2.png';
// import Img10_3 from '../img/catalog/10/3.png';
// import Img10_4 from '../img/catalog/10/4.png';
// import Img10_5 from '../img/catalog/10/5.png';

export const catalogList = [
  {
    id: 1,
    brand: 'lalaku',
    title: 'Трусики-подгузники LaLaku для мальчиков',
    description:
      'Детские трусики-подгузники «LaLaKu» специально созданы из натуральных материалов с использованием передовых технологий, чтобы обеспечить максимальный комфорт для Вашего ребенка. Детские подгузники «LaLaKu» содержат: специальный верхний слой с добавлением лосьона алоэ вера; специальные материалы, обеспечивающие «дыхание» нежной кожи малыша; специально впитывающую систему, отлично распределяющую жидкость внутри и защищающую от протекания до 10 часов. Детские подгузники «LaLaKu» обеспечат надежную защиту Вашего малыша! Внимание! Хранить упаковку в местах недоступных детям, защищенным от влаги. Порванные или использованные подгузники необходимо выбросить.',
    gallery: [Img1, Img2, Img3, Img4],
    box: [
      { size: '3', weight: '4-8' },
      { size: '4', weight: '7-14' },
      { size: '5', weight: '10-17' },
      { size: '6', weight: '15+' },
    ],
    first_text: 'Размер',
    second_text: 'Кг',
    sizes: ['60', '54', '48', '46'],
  },
  {
    id: 2,
    brand: '',
    title: 'Трусики-подгузники LaLaku для девочек',
    description:
      'Детские трусики-подгузники «LaLaKu для девочек» специально созданы из натуральных материалов с использованием передовых технологий, чтобы обеспечить максимальный комфорт для Вашего ребенка. Детские подгузники «LaLaKu для девочек» содержат: специальный верхний слой с добавлением лосьона алоэ вера; специальные материалы, обеспечивающие «дыхание» нежной кожи малыша; специально впитывающую систему, отлично распределяющую жидкость внутри и защищающую от протекания до 10 часов. Детские подгузники «LaLaKu для девочек» обеспечат надежную защиту Вашего малыша! Внимание! Хранить упаковку в местах недоступных детям, защищенным от влаги. Порванные или использованные подгузники необходимо выбросить.      ',
    gallery: [Img2_1, Img2_2, Img2_3, Img2_4],
    box: [
      { size: '3', weight: '4-8' },
      { size: '4', weight: '7-14' },
      { size: '5', weight: '10-17' },
      { size: '6', weight: '15+' },
    ],
    first_text: 'Размер',
    second_text: 'Кг',
    sizes: ['60', '54', '48', '46'],
  },
  {
    id: 3,
    brand: '',
    title: 'Трусики-подгузники LaLaku',
    description:
      'Детские трусики-подгузники «LaLaKu» специально созданы из натуральных материалов с использованием передовых технологий, чтобы обеспечить максимальный комфорт для Вашего ребенка. Детские подгузники «LaLaKu» содержат: специальный верхний слой с добавлением лосьона алоэ вера; специальные материалы, обеспечивающие «дыхание» нежной кожи малыша; специально впитывающую систему, отлично распределяющую жидкость внутри и защищающую от протекания до 10 часов. Детские подгузники «LaLaKu» обеспечат надежную защиту Вашего малыша! Внимание! Хранить упаковку в местах недоступных детям, защищенным от влаги. Порванные или использованные подгузники необходимо выбросить.',
    gallery: [Img3_1, Img3_2, Img3_3, Img3_4],
    box: [
      { size: '3', weight: '4-8' },
      { size: '4', weight: '7-14' },
      { size: '5', weight: '10-17' },
      { size: '6', weight: '15+' },
    ],
    first_text: 'Размер',
    second_text: 'Кг',
    sizes: ['54', '46', '40', '36'],
  },
  {
    id: 4,
    brand: '',
    title: 'Ленточные подгузники LaLaku',
    description:
      'Детские ленточные подгузники «LaLaKu» специально созданы из натуральных материалов с использованием передовых технологий, чтобы обеспечить максимальный комфорт для Вашего ребенка. Детские подгузники «LaLaKu» содержат: специальный верхний слой с добавлением лосьона алоэ вера; специальные материалы, обеспечивающие «дыхание» нежной кожи малыша; специально впитывающую систему, отлично распределяющую жидкость внутри и защищающую от протекания до 10 часов. Детские подгузники «LaLaKu» обеспечат надежную защиту Вашего малыша! Внимание! Хранить упаковку в местах недоступных детям, защищенным от влаги. Порванные или использованные подгузники необходимо выбросить.',
    gallery: [Img4_1, Img4_2, Img4_3, Img4_4, Img4_5],
    box: [
      { size: '1', weight: '2-5' },
      { size: '2', weight: '3-6' },
      { size: '3', weight: '4-9' },
      { size: '4', weight: '7-18' },
      { size: '5', weight: '11-25' },
    ],
    first_text: 'Размер',
    second_text: 'Кг',
    sizes: ['160', '102', '80', '68', '60'],
  },
  // {
  //   id: 5,
  //   brand: 'lalaku',
  //   title: 'Подгузники LALAKU Малчик',
  //   description: '',
  //   gallery: [Img5_1, Img5_2, Img5_3, Img5_4],
  //   box: [
  //     { size: '3', weight: '4-8' },
  //     { size: '4', weight: '7-14' },
  //     { size: '5', weight: '10-17' },
  //     { size: '6', weight: '15+' },
  //   ],
  //   first_text: 'Размер',
  //   second_text: 'Кг',
  //   sizes: ['52', '44', '38', '34'],
  // },
  // {
  //   id: 6,
  //   brand: 'lalaku',
  //   title: 'Подгузники LALAKU Малчик',
  //   description: '',
  //   gallery: [Img6_1, Img6_2, Img6_3, Img6_4, Img6_5],
  //   box: [
  //     { size: '2', weight: '3-6' },
  //     { size: '3', weight: '4-8' },
  //     { size: '4', weight: '7-14' },
  //     { size: '5', weight: '10-17' },
  //     { size: '6', weight: '15+' },
  //   ],
  //   first_text: 'Размер',
  //   second_text: 'Кг',
  //   sizes: ['84', '70', '60', '52', '46'],
  // },
  // {
  //   id: 7,
  //   brand: 'lalaku',
  //   title: 'Подгузники LALAKU Малчик',
  //   description: '',
  //   gallery: [Img7_1, Img7_2, Img7_3, Img7_4],
  //   box: [
  //     { size: '3', weight: '4-8' },
  //     { size: '4', weight: '7-14' },
  //     { size: '5', weight: '10-17' },
  //     { size: '6', weight: '15+' },
  //   ],
  //   first_text: 'Размер',
  //   second_text: 'Кг',
  //   sizes: ['26', '22', '19', '17'],
  // },
  // {
  //   id: 8,
  //   brand: 'lalaku',
  //   title: 'Подгузники LALAKU Малчик',
  //   description: '',
  //   gallery: [Img8_1, Img8_2, Img8_3, Img8_4],
  //   box: [
  //     { size: '3', weight: '4-8' },
  //     { size: '4', weight: '7-18' },
  //     { size: '5', weight: '11-25' },
  //     { size: '6', weight: '16+' },
  //   ],
  //   first_text: 'Размер',
  //   second_text: 'Кг',
  //   sizes: ['60', '50', '44', '40'],
  // },
  // {
  //   id: 9,
  //   brand: 'lalaku',
  //   title: 'Подгузники LALAKU Малчик',
  //   description: '',
  //   gallery: [Img9_1, Img9_2, Img9_3, Img9_4, Img9_5],
  //   box: [
  //     { size: '1', weight: '2-5' },
  //     { size: '2', weight: '3-6' },
  //     { size: '3', weight: '4-9' },
  //     { size: '4', weight: '7-18' },
  //     { size: '5', weight: '11-25' },
  //   ],
  //   first_text: 'Размер',
  //   second_text: 'Кг',
  //   sizes: ['76', '70', '58', '50', '40'],
  // },
  // {
  //   id: 10,
  //   brand: 'lalaku',
  //   title: 'Подгузники LALAKU Малчик',
  //   description: '',
  //   gallery: [Img10_1, Img10_2, Img10_3, Img10_4, Img10_5],
  //   box: [
  //     { size: '1', weight: '2-5' },
  //     { size: '2', weight: '3-6' },
  //     { size: '3', weight: '4-9' },
  //     { size: '4', weight: '7-18' },
  //     { size: '5', weight: '11-25' },
  //   ],
  //   first_text: 'Размер',
  //   second_text: 'Кг',
  //   sizes: ['76', '70', '58', '50', '40'],
  // },
];
