import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Logo from '../img/new_logo.png';
import { FaTelegramPlane } from 'react-icons/fa';
import { useStore } from '../context/store';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { openMenu, setOpenMenu } = useStore();
  const path = useParams();

  useEffect(() => {
    setIsOpen(false);
    setOpenMenu(false);
  }, [path, setOpenMenu]);

  return (
    <div className="header container">
      <div className="header__content">
        <Link to={'/'}>
          <img
            style={{ height: '35px', width: '100px' }}
            className="header__logo"
            src={Logo}
            alt="logo"
          />
        </Link>
        <div
          className={`header__nav ${
            openMenu && 'header__nav_visible header__nav_display'
          }`}
        >
          <div className="dropdown d-inline header__nav-item">
            <Link className="header__nav-item text-decoration-none" to="/">
              Главная
            </Link>
            <button
              type="button"
              data-bs-toggle="dropdown"
              className="btn btn-sm btn-link dropdown-toggle p-0 m-0 header__nav-item pr-5 fw-normal mt-2 mt-lg-0"
              style={{
                width: '70px',
                backgroundColor: 'transparent',
                padding: '0',
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
              Каталог
            </button>
            {isOpen && (
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/catalog/diapers">
                  Детские подгузники
                </Link>
                <Link className="dropdown-item" to="/catalog/masks">
                  Медицинские маски
                </Link>
                <Link className="dropdown-item" to="/catalog/women">
                  Женские гигиенические прокладки
                </Link>
                <Link className="dropdown-item" to="/catalog/pads">
                  Подмышечные прокладки
                </Link>
                <Link className="dropdown-item" to="/catalog/mama">
                  Грудные прокладки
                </Link>
              </div>
            )}
          </div>
          {/* <Link className="header__nav-item text-decoration-none" to="/sales">
            Акции
          </Link>
          <Link
            className="header__nav-item text-decoration-none"
            to="/partners"
          >
            Партнеры
          </Link> */}
          <Link className="header__nav-item text-decoration-none" to="/about">
            О нас
          </Link>
        </div>
        <div className="header__social social">
          <div className="social__title">Мы в социальных сетях</div>
          <div className="social__set">
            {/* <Link
              to="https://www.facebook.com/Lalaku.Kazakhstan/"
              target="_blank"
              className="social__icon-wrap"
            >
              <div className="social__icon social__icon_fb"></div>
            </Link> */}
            <Link
              to="https://www.instagram.com/Lalaku.Kazakhstan/"
              target="_blank"
              className="social__icon-wrap"
            >
              <div className="social__icon social__icon_insta"></div>
            </Link>
            <Link
              to="https://t.me/+77079631989"
              target="_blank"
              className="social__icon-wrap text-white text-decoration-none"
            >
              <div className="fa-lg">
                <FaTelegramPlane />
              </div>
            </Link>
            {/* <Link
              to="https://www.youtube.com/channel/UCCHvBrlmF-gCUR60zFiAexw"
              target="_blank"
              className="social__icon-wrap text-white text-decoration-none"
            >
              <div className="fa-lg">
                <FaYoutube />
              </div>
            </Link> */}
          </div>
        </div>
        <div className="header__burger js-tgl-dropdown-menu">
          <button
            className={`burger ${openMenu && 'burger_hidden'}`}
            onClick={() => setOpenMenu(true)}
          ></button>
          <button
            className={`burger-close ${openMenu && 'burger-close_visible'}`}
            onClick={() => setOpenMenu(false)}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default Header;
